import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { EEnv } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@core/services/Utils';

import * as Sentry from '@sentry/angular-ivy';
import { captureConsoleIntegration } from '@sentry/integrations';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// Load fr locale for day and month translations
import 'dayjs/locale/fr';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const subdomain = window?.location?.hostname?.split('.')[0];
let environment: 'production' | 'preproduction' | 'demo' | 'development';

if (subdomain === 'app') {
    Utils.env = EEnv.Prod;
    environment = 'production';
} else if (subdomain === 'preprod') {
    Utils.env = EEnv.Preprod;
    environment = 'preproduction';
} else if (subdomain === 'demo') {
    Utils.env = EEnv.Demo;
    environment = 'demo';
} else {
    Utils.env = EEnv.Dev;
    environment = 'development';
}

console.log('Running environment: ', Utils.env);

let dsn = 'https://422f3e26f7d64e7da691588721c1e84f@sentry.mapui.fr/2';

if (Utils.env !== EEnv.Prod) {
    dsn = null;
}

Sentry.init({
    dsn,
    integrations: [
        captureConsoleIntegration({
            levels: ['error']
        }),
        Sentry.browserTracingIntegration()
    ],
    environment: environment,
    enabled: Utils.env === EEnv.Prod,

    tracesSampleRate: 1.0
});

if (Utils.env === EEnv.Prod) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((e) => console.error(e));
