import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Translation, TranslocoModule, TranslocoService, provideTransloco } from '@jsverse/transloco';
import { EEnv, IMember } from '@mapuilabs/mpl-interfaces';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';

import { Utils } from './core/services/Utils';
import { TranslocoHttpLoader } from './transloco-loader';
import dayjs from 'dayjs';

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English'
                    },
                    {
                        id: 'fr',
                        label: 'Français'
                    }
                ],
                defaultLang: 'fr',
                fallbackLang: 'fr',
                reRenderOnLangChange: true,
                prodMode: Utils.env !== EEnv.Dev
            },
            loader: TranslocoHttpLoader
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            deps: [TranslocoService, CookieService],
            useFactory:
                (translocoService: TranslocoService, cookieService: CookieService) => (): Promise<Translation> => {
                    let lang = translocoService.getDefaultLang();
                    // Get user language
                    try {
                        const userCookie = cookieService.get('user');
                        const user = JSON.parse(userCookie) as IMember;
                        lang = user.config.language;
                    } catch (error) {
                        console.error(error);
                    }
                    translocoService.setActiveLang(lang);
                    dayjs.locale(lang);
                    return firstValueFrom(translocoService.load(lang));
                },
            multi: true
        }
    ]
})
export class TranslocoRootModule {}
