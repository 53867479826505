import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@jsverse/transloco';
import * as Sentry from '@sentry/angular-ivy';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { IconRegisterModule } from './icon-register.module';
import { LayoutModule } from './layout/layout.module';
import { MpMatPaginatorIntl } from './paginator-intl';

import { TranslocoRootModule } from './transloco-root.module';
import { DayjsDateModule, MatDayjsDateModule } from './shared/material-dayjs-adapter/material-dayjs-adapter.module';

registerLocaleData(localeFr);
registerLocaleData(localeEn);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        HttpClientModule,
        IconRegisterModule,
        RouterModule.forRoot(appRoutes),
        LayoutModule,
        TranslocoModule,
        TranslocoRootModule,
        DayjsDateModule,
        MatDayjsDateModule
    ],
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    providers: [
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { pageSizeOptions: [10, 25, 50, 100] } },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { hideDelay: 200, showDelay: 400 } },
        { provide: MatPaginatorIntl, useClass: MpMatPaginatorIntl },
        { provide: NG_SCROLLBAR_OPTIONS, useValue: { visibility: 'hover' } }
    ]
})
export class AppModule {}
